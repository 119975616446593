// extracted by mini-css-extract-plugin
export var btn = "banner-module--btn--vUzs6";
export var btnBlock = "banner-module--btnBlock--poNan";
export var container = "banner-module--container--sjzbC";
export var hideMobBlock = "banner-module--hideMobBlock---9gp7";
export var img = "banner-module--img--D3n3w";
export var imgHalva = "banner-module--imgHalva--8Zx4M";
export var imgSvoboda = "banner-module--imgSvoboda--U9wgl";
export var mobBtn = "banner-module--mobBtn--u+leF";
export var secondContainer = "banner-module--secondContainer--ivZGM";
export var textBlock = "banner-module--textBlock--ERcy9";
export var wrapImg = "banner-module--wrapImg--SImKL";