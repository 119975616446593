// extracted by mini-css-extract-plugin
export var block1 = "betterHalva-module--block1--Mm737";
export var block2 = "betterHalva-module--block2--Hnyo7";
export var block3 = "betterHalva-module--block3--E3o3+";
export var grid = "betterHalva-module--grid--AvU0a";
export var head = "betterHalva-module--head--CRyuZ";
export var headFullWidth = "betterHalva-module--headFullWidth--KVoio";
export var icon = "betterHalva-module--icon--Gc6Vs";
export var img = "betterHalva-module--img--0KpJ2";
export var section = "betterHalva-module--section--N6Naz";
export var title = "betterHalva-module--title--z537E";