import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import type { GatsbyImageProps } from "gatsby-plugin-image"

const imgQuery = graphql`
  {
    img: file(relativePath: { eq: "Banners/KartaSvoboda/svoboda.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`

export default function Img(props: Omit<GatsbyImageProps, "image">) {
  const { img } = useStaticQuery(imgQuery)

  return <GatsbyImage {...props} image={getImage(img)!} />
}
